import React, { useEffect, useState } from 'react';

import type { Options } from 'react-markdown';

import { st, classes } from './Markdown.st.css';

const DummyMarkdown: React.FC<{ className?: string }> = (props) => {
  return <div className={props.className}>{props.children}</div>;
};

export const Markdown: React.FC<{
  text: string;
  className?: string;
}> = ({ text, className }) => {
  const [remarkBreaks, setRemarkBreaks] = useState<() => void>();

  useEffect(() => {
    if (!remarkBreaks) {
      import('remark-breaks').then((module) => {
        setRemarkBreaks(() => module.default);
      });
    }
  }, [remarkBreaks]);

  const [rehypeMinifyWhitespace, setRehypeMinifyWhitespace] =
    useState<() => void>();

  useEffect(() => {
    if (!rehypeMinifyWhitespace) {
      import('rehype-minify-whitespace').then((module) => {
        setRehypeMinifyWhitespace(() => module.default);
      });
    }
  }, [rehypeMinifyWhitespace]);

  const [ReactMarkdown, setReactMarkdown] =
    useState<React.ComponentType<Options> | null>(null);

  useEffect(() => {
    if (!ReactMarkdown) {
      import('react-markdown').then((module) => {
        setReactMarkdown(() => module.default);
      });
    }
  }, [ReactMarkdown]);

  if (!ReactMarkdown || !remarkBreaks || !rehypeMinifyWhitespace) {
    return <DummyMarkdown className={className}>{text}</DummyMarkdown>;
  }

  return (
    <div className={className} data-hook="Markdown">
      <ReactMarkdown
        className={st(classes.root)}
        remarkPlugins={[remarkBreaks]}
        rehypePlugins={[rehypeMinifyWhitespace]}
      >
        {text}
      </ReactMarkdown>
    </div>
  );
};
